<template>
    <b-modal
    :id="$route.name + 'CreateModal'"
    title="Добавить тариф"
    size="lg"
    hide-footer
    mo-enforce-focus
    :no-close-on-backdrop="true"
    centered
    @close="clearData"
    >
    <b-row class="d-flex justify-content">
        <b-col cols="6">
            <b-row>
                <b-col>
                    <label>Подразделение: <i style="color: red;">*</i> </label>
                    <b-form-group>
                        <v-select
                        label="name"
                        v-model="createTariffPerfromer.division_id"
                        :options="division"
                        :reduce="item => item.id"
                        >
                        </v-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group>
                        <label>Наименование: <i style="color: red;">*</i></label>
                        <b-input size="sm" type="text" v-model="createTariffPerfromer.name"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group>
                        <label>Тип тарифа: <i style="color: red;">*</i></label>
                        <v-select
                        label="name"
                        v-model="createTariffPerfromer.type_tariff_performer_id"
                        :options="type_tariff_performer"
                        :reduce="item => item.id"
                        ></v-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group>
                        <label>Класс автомобиля: <i style="color: red;">*</i></label>
                        <v-select
                        label="name"
                        v-model="createTariffPerfromer.car_class_id"
                        :options="car_classes"
                        :reduce="item => item.id"
                        ></v-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group>
                        <label>Порядок: <i style="color: red;">*</i></label>
                        <b-input size="sm" type="number" v-model="createTariffPerfromer.sort_number"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
            <div>
                <b-col>
                    <label class="d-flex">
                        <b-form-checkbox v-model="createTariffPerfromer.basis" id="1"></b-form-checkbox>
                        <div style="user-select: none; font-size: 16px;">
                            Основной
                        </div>
                    </label>
                </b-col>
                <b-col class="mt-75">
                    <label class="d-flex">
                        <b-form-checkbox v-model="createTariffPerfromer.blocked" id="2"></b-form-checkbox>
                        <div style="user-select: none; font-size: 16px;">
                            Блокирован
                        </div>
                    </label>
                </b-col>
            </div>
        </b-row>
        </b-col>
            <b-col>
                <div style="overflow: scroll; height: 450px; margin-top: 25px;">
                    <b-form-group style="column-count: 2;">
                        <b-form-checkbox
                        v-for="option in dop_options"
                        :key="option.id"
                        v-model="createTariffPerfromer.options"
                        :value="option.id"
                        name="flavour-3a"
                      >
                        {{ option.name }}
                      </b-form-checkbox>
                    </b-form-group>
                </div>
            </b-col>
            <b-col cols="12" class="d-flex justify-content-end">
                <b-col style="margin-top: 30px;">
                    <div>{{ dateTime.hours }}:{{ dateTime.minutes < 10 ? '0' + dateTime.minutes : dateTime.minutes}}</div>
                </b-col>
                <b-button
                :disabled="submitButtonDisabled"
                class="mt-2 mr-2 col-md-5"
                variant="primary"
                @click="createTariffPerformers">
                    Создать
                </b-button>
                <b-button class="mt-2 col-md-5" variant="secondary" @click="clearData">
                    Отмена
                </b-button>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

const date = new Date()
export default {
    props: ['division', 'type_tariff_performer', 'car_classes', 'dop_options', 'tariffPerformerBase'],
    components: {
        ToastificationContent,
        vSelect
    },
    data() {
        return {
            submitButtonDisabled: false,
            createTariffPerfromer: {
                name: null,
                division_id: null,
                type_tariff_performer_id: null,
                car_class_id: null,
                sort_number: null,
                dop_info: null,
                blocked: false,
                basis: false,
                options: []
            },
            dateTime: {
                hours: date.getHours(),
                minutes: date.getMinutes()
            },
        }
    },
    methods: {
        createTariffPerformers() {
            this.createTariffPerfromer.basis == true ? 1 : 0
            this.createTariffPerfromer.blocked == true ? 1 : 0
            this.submitButtonDisabled = true
            this.$http
                .post('tariff-performers/base', this.createTariffPerfromer)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: res.data.message,
                        },
                    })
                    this.clearData()
                    this.$emit('refresh')
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                    .finally(() => {
                        this.submitButtonDisabled = false
                    })
                })
        }, 
        clearData() {
            this.createTariffPerfromer = {
                name: null,
                division_id: null,
                type_tariff_performer_id: null,
                car_class_id: null,
                sort_number: null,
                dop_info: null,
                blocked: null,
                basis: null,
                options: []
            },
            this.dateTime = {
                hours: date.getHours(),
                minutes: date.getMinutes()
            },
            this.$bvModal.hide(this.$route.name + 'CreateModal')
        }
    }
}
</script>
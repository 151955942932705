<template>
    <b-modal
    :id="'EditPerformerTariff'"
    title="Изменить тариф"
    size="lg"
    hide-footer
    mo-enforce-focus
    :no-close-on-backdrop="true"
    centered
    @show="editTariffPerformer"
    @close="clearData"
    >
    <b-row class="d-flex justify-content">
        <b-col cols="6">
            <b-row>
                <b-col>
                    <label>Подразделение: <i style="color: red;">*</i> </label>
                    <b-form-group>
                        <v-select
                        label="name"
                        v-model="EditTariffPerfromer.division_id"
                        :options="division"
                        :reduce="item => item.id"
                        >
                        </v-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group>
                        <label>Наименование: <i style="color: red;">*</i></label>
                        <b-input size="sm" type="text" v-model="EditTariffPerfromer.name"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group>
                        <label>Тип тарифа: <i style="color: red;">*</i></label>
                        <v-select
                        label="name"
                        v-model="EditTariffPerfromer.type_tariff_performer_id"
                        :options="type_tariff_performer"
                        :reduce="item => item.id"
                        ></v-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group>
                        <label>Класс автомобиля: <i style="color: red;">*</i></label>
                        <v-select
                        label="name"
                        v-model="EditTariffPerfromer.car_class_id"
                        :options="car_classes"
                        :reduce="item => item.id"
                        ></v-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group>
                        <label>Порядок: <i style="color: red;">*</i></label>
                        <b-input size="sm" type="number" v-model="EditTariffPerfromer.sort_number"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
            <div>
                <b-col>
                    <label class="d-flex">
                        <b-form-checkbox v-model="EditTariffPerfromer.basis" id="1"></b-form-checkbox>
                        <div style="user-select: none; font-size: 16px;">
                            Основной
                        </div>
                    </label>
                </b-col>
                <b-col class="mt-75">
                    <label class="d-flex">
                        <b-form-checkbox v-model="EditTariffPerfromer.blocked" id="2"></b-form-checkbox>
                        <div style="user-select: none; font-size: 16px;">
                            Блокирован
                        </div>
                    </label>
                </b-col>
            </div>
        </b-row>
        </b-col>
            <b-col>
                <div style="overflow: scroll; height: 450px; margin-top: 25px;">
                    <b-form-group style="column-count: 2;">
                            <b-form-checkbox
                            v-for="option in dop_options"
                            :key="option.id"
                            v-model="EditTariffPerfromer.options"
                            :value="option.id"
                            name="flavour-3a"
                            >
                            {{ option.name }}
                            </b-form-checkbox>
                    </b-form-group>
                </div>
            </b-col>
            <b-col cols="12" class="d-flex justify-content-end">
                <b-col style="margin-top: 30px;">
                    <div>{{ dateTime.hours }}:{{ dateTime.minutes < 10 ? '0' + dateTime.minutes : dateTime.minutes}}</div>
                </b-col>
                <b-button
                :disabled="submitButtonDisabled"
                class="mt-2 mr-2 col-md-5"
                variant="primary"
                @click="EditTariffPerformers">
                    Изменить
                </b-button>
                <b-button class="mt-2 col-md-5" variant="secondary" @click="clearData">
                    Отмена
                </b-button>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

const date = new Date()
export default {
    props: ['division', 'type_tariff_performer', 'car_classes', 'dop_options', 'id'],
    components: {
        ToastificationContent,
        vSelect
    },
    data() {
        return {
            submitButtonDisabled: false,
            EditTariffPerfromer: {
                name: null,
                division_id: null,
                type_tariff_performer_id: null,
                car_class_id: null,
                sort_number: null,
                dop_info: null,
                blocked: null,
                basis: null,
                options: []
            },
            dateTime: {
                hours: date.getHours(),
                minutes: date.getMinutes()
            },
        }
    },
    methods: {
        editTariffPerformer() {
            this.$http
                .get(`tariff-performers/base/${this.id}/edit`)
                .then(res => {
                    this.EditTariffPerfromer.name = res.data.name
                    this.EditTariffPerfromer.division_id = res.data.division_id
                    this.EditTariffPerfromer.type_tariff_performer_id = res.data.type_tariff_performer
                    this.EditTariffPerfromer.car_class_id = res.data.car_class_id
                    this.EditTariffPerfromer.dop_info = res.data.dop_info
                    this.EditTariffPerfromer.basis = res.data.basis == 1 ? true : false
                    this.EditTariffPerfromer.blocked = res.data.blocked == 1 ? true : false
                    this.EditTariffPerfromer.sort_number = res.data.sort_number;
                    res.data.options.forEach(element => {
                        this.EditTariffPerfromer.options.push(element.tariff_option_id)
                    });
                })
        },
        EditTariffPerformers() {
            this.EditTariffPerfromer.basis == true ? 1 : 0
            this.EditTariffPerfromer.blocked == true ? 1 : 0
            this.submitButtonDisabled = true
            this.$http
                .patch(`tariff-performers/base/${this.id}`, this.EditTariffPerfromer)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: res.data.message,
                        },
                    })
                    this.clearData()
                    this.$emit('refresh')
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                    .finally(() => {
                        this.submitButtonDisabled = false
                    })
                })
        },
        clearData() {
            this.EditTariffPerfromer = {
                name: null,
                division_id: null,
                type_tariff_performer_id: null,
                car_class_id: null,
                sort_number: null,
                dop_info: null,
                blocked: null,
                basis: null,
                options: []
            },
            this.dateTime = {
                hours: date.getHours(),
                minutes: date.getMinutes()
            },
            this.$bvModal.hide('EditPerformerTariff')
        }
    }
}
</script>